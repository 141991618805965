import axios from "axios";
import BaseService from "@/services/base/base.service";

class BillingActivePackageService extends BaseService {
    get modelName() {
        return "BillingActivePackage"
    }

    get() {
        return axios.get(this.getAllUrl()).then(response => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
    }
}

export default new BillingActivePackageService()
