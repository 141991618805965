import Vue from 'vue'
import Vuex from 'vuex'
import defaultClient from "./modules/default-client";
import defaultEmployee from "./modules/default-employee";
import warehouse from "./modules/warehouse";
import organization from "./modules/organization";
import currency from "./modules/currency";
import ePos from "./modules/epos";
import account from "./modules/account";
import sale from "./modules/sale";
import permission from "./modules/permission";
import packageModule from "./modules/package";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        defaultClient,
        defaultEmployee,
        warehouse,
        organization,
        ePos,
        currency,
        account,
        sale,
        packageModule,
        permission
    }
})
