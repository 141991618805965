import BaseService from "../base/base.service";
import axios from "axios";

class OrganizationLayoutService extends BaseService {
    get modelName() {
        return 'CompanyParams'
    }
    getAll() {
        return axios.get(this.getAllUrl()).then(response => response.data).catch(error => {
            throw error
        });
    }
}

export default new OrganizationLayoutService();
