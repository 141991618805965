import Vue from 'vue'
import VueRouter from 'vue-router'
import App from '../views/app'
import CheckAccessMiddleware from "../utils/CheckAccessMiddleware";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'App',
        redirect: '/app/sale',
        component: App,
        meta: {auth: true},
        children: [
            {
                path: 'app/sale',
                component: () => import(/* webpackChunkName: "product" */ '../../src/views/app/sale'),
                redirect: '/app/sale/create',
                meta: {auth: true},
                children: [
                    {
                        path: 'create',
                        name: 'create-sale',
                        component: () => import(/* webpackChunkName: "new-sale" */ '../../src/views/app/sale/NewSale'),
                        meta: {auth: true},
                    },
                    {
                        path: 'all',
                        name: 'all-sales',
                        component: () => import(/* webpackChunkName: "new-sale" */ '../../src/views/app/sale/AllSales'),
                        meta: {auth: true},
                    },
                ]
            },
            {
                path: 'app/refund',
                component: () => import(/* webpackChunkName: "refund" */ '../../src/views/app/refund'),
                redirect: '/app/refund/create',
                meta: {auth: true},
                children: [
                    {
                        path: 'create',
                        name: 'create-refund',
                        component: () => import(/* webpackChunkName: "new-refund" */ '../../src/views/app/refund/NewRefund'),
                        meta: {auth: true},
                    },
                    {
                        path: 'all',
                        name: 'all-refunds',
                        component: () => import(/* webpackChunkName: "new-refund" */ '../../src/views/app/refund/AllRefunds'),
                        meta: {auth: true},
                    },
                ]
            },
            {
                path: 'app/warehouse-item',
                component: () => import(/* webpackChunkName: "product" */ '../../src/views/app/warehouseItem'),
                redirect: '/app/warehouse-item/all',
                meta: {auth: true},
                children: [
                    {
                        path: 'all',
                        name: 'all-warehouse-items',
                        meta: {auth: true},
                        component: () => import(/* webpackChunkName: "all-warehouse-items" */ '../../src/views/app/warehouseItem/AllWarehouseItems')
                    },
                ]
            },
        ]
    },
    {
        path: '/user',
        name: 'login',
        component: () => import(/* webpackChunkName: "about" */ '../views/user'),
        redirect: '/user/login',
        meta: {auth: false},
        children: [
            {
                path: '/user/login',
                meta: {auth: false},
                component: () => import(/* webpackChunkName: "product" */ '../../src/views/user/Login'),
            }
        ]
    }
]

const router = new VueRouter({
    linkActiveClass: "active",
    routes,
    mode: "hash"
});
router.beforeEach(CheckAccessMiddleware);
export default router
