import {ApiHost} from '@/constants/settings'
import axios from "axios";

class AccountSettingsService {
    reloadPermissions({username}) {
        return axios.get(`${ApiHost}/api/AccountPermission/${username}`);
    }

    changePassword(newPassword) {
        return axios.post(`${ApiHost}/api/ChangePassword`, {
            Password: newPassword
        });
    }
}

export default new AccountSettingsService()
