import BillingActivePackageService from "@/services/billing/billing.active.package.service";

export default {
    state: {
        activePackage: null
    },
    getters: {
        getActivePackage: state => state.activePackage,
    },
    actions: {
        async loadActivePackage({commit}) {
            await BillingActivePackageService.get().then(async function (activePackage) {
                await commit('setActivePackage', {activePackage: activePackage});
            });
        }
    },
    mutations: {
        setActivePackage(state, {activePackage}) {
            state.activePackage = activePackage;
        },
    },
}
