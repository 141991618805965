export default {
    state: {
        isCheckedItemStateMoreThanZero: false,
        isCheckedViewCostPrice: false,
        isCheckedSaleWithoutCurrency: false,
        isCheckedAccessToDebtSale: true,
        offChangeSalePrice: false,
        compareSalePriceToPurchasePrice: false,
    },
    getters: {
        getCheckedItemStateMoreThanZero(state) {
            return state.isCheckedItemStateMoreThanZero;
        },
        getCheckedViewCostPrice(state) {
            return state.isCheckedViewCostPrice;
        },
        getCheckedSaleWithoutCurrency(state) {
            return state.isCheckedSaleWithoutCurrency;
        },
        getCheckedAccessToDebtSale(state) {
            return state.isCheckedAccessToDebtSale;
        },
        offChangeSalePrice(state) {
            return state.offChangeSalePrice;
        },
        getCompareSalePriceToPurchasePrice(state) {
            return state.compareSalePriceToPurchasePrice;
        }
    },
    mutations: {
        SetCheckedItemStateMoreThanZero(state, {checkedItemStateMoreThanZero}) {
            state.isCheckedItemStateMoreThanZero = checkedItemStateMoreThanZero
        },
        SetValueViewCostPrice(state, {checkedViewCostPrice}) {
            state.isCheckedViewCostPrice = checkedViewCostPrice
        },
        SetCheckedSaleWithoutCurrency(state, {checkedSaleWithoutCurrency}) {
            state.isCheckedSaleWithoutCurrency = checkedSaleWithoutCurrency;
        },
        SetCheckedAccessToDebtSale(state, {checkedAccessToDebtSale}) {
            state.isCheckedAccessToDebtSale = checkedAccessToDebtSale
        },
        SetOffChangeSalePrice(state, {offChangeSalePrice}) {
            state.offChangeSalePrice = offChangeSalePrice;
        },
        setCompareSalePriceToPurchasePrice(state, {compareSalePriceToPurchasePrice}) {
            state.compareSalePriceToPurchasePrice = compareSalePriceToPurchasePrice;
        }
    }
}
