<template>
    <b-overlay class="d-flex">
        <Sidebar v-if="permissions.length > 0"/>
        <b-container fluid>
            <b-overlay :show="loading" variant="light">
                <router-view v-if="!loading"/>
            </b-overlay>
        </b-container>
    </b-overlay>
</template>

<script>
import Sidebar from "../../components/Sidebar";
import WarehouseService from "../../services/warehouse/warehouse.service";
import OrganizationLayoutService from "../../services/organization/organization.layout.service";
import {mapGetters} from "vuex";
import $store from "../../store";

export default {
    name: "index",
    components: {Sidebar},
    data() {
        return {
            loading: false
        }
    },
    computed: {
        ...mapGetters({
            defaultClient: 'getDefaultClient',
            defaultEmployee: 'getDefaultEmployee',
            selectedWarehouse: 'selectedWarehouse',
            permissions: 'getAllPermissions'
        }),
    },
    methods: {
        async loadWarehouses() {
            let vm = this;
            let params = {
                skip: 0,
                limit: null,
                query: '',
                sort: null,
                desc: null
            };
            await vm.$store.dispatch('getAllWarehouses', params).then(function (warehouses) {
                if (warehouses.length === 0) {
                    localStorage.removeItem('selectedWarehouse');
                    vm.$store.commit('selectWarehouse', {warehouse: null});
                    return;
                }
                if (vm.$route.query.warehouse) {
                    WarehouseService.get(+vm.$route.query.warehouse).then(warehouse => {
                        vm.$store.commit('selectWarehouse', {warehouse: warehouse});
                    });
                } else {
                    let selectWarehouse = null;
                    if (!localStorage.getItem('selectedWarehouse')) {
                        selectWarehouse = warehouses[0];
                    } else {
                        let selectedWarehouse = JSON.parse(localStorage.getItem('selectedWarehouse'));
                        let findInWarehouses = warehouses.find(warehouse => {
                            return selectedWarehouse.WarehouseId === warehouse.WarehouseId;
                        });
                        if (findInWarehouses)
                            selectWarehouse = selectedWarehouse;
                        else
                            selectWarehouse = warehouses[0];
                    }

                    //set to localstorage
                    vm.$store.commit('selectWarehouse', {warehouse: selectWarehouse});
                    localStorage.setItem('selectedWarehouse', JSON.stringify(selectWarehouse));
                }
            });
        },
        async getCompanyParams() {
            try {
                let companyParams = await OrganizationLayoutService.getAll().catch(err => console.log(err));
                let statusEPos = false;
                let isCheckedItemStateMoreThanZero = false;
                let checkedViewCostPrice = false;
                let isCheckedSaleWithoutCurrency = false;
                let isCheckedAccessToDebtSale = true;
                let offChangeSalePrice = false;
				let compareSalePriceToPurchasePrice = false;

                if (companyParams) {
                    let uiParams = JSON.parse(companyParams);
                    statusEPos = typeof (uiParams.statusEPos) !== 'undefined' ? uiParams.statusEPos : false;
                    isCheckedItemStateMoreThanZero = typeof (uiParams.isCheckedItemStateMoreThanZero) !== 'undefined' ? uiParams.isCheckedItemStateMoreThanZero : false;
                    checkedViewCostPrice = typeof (uiParams.checkedViewCostPrice) !== 'undefined' ? uiParams.checkedViewCostPrice : false;
                    isCheckedSaleWithoutCurrency = typeof (uiParams.isCheckedSaleWithoutCurrency) !== 'undefined' ? uiParams.isCheckedSaleWithoutCurrency : false;
                    offChangeSalePrice = typeof (uiParams.offChangeSalePrice) !== 'undefined' ? uiParams.offChangeSalePrice : false;
                    isCheckedAccessToDebtSale = typeof (uiParams.isCheckedAccessToDebtSale) !== 'undefined' ? uiParams.isCheckedAccessToDebtSale : true;

					compareSalePriceToPurchasePrice = typeof (uiParams.compareSalePriceToPurchasePrice) !== 'undefined' ? uiParams.compareSalePriceToPurchasePrice : false;
                }
                $store.commit('setStatusEPos', {statusEPos: statusEPos});
                $store.commit('SetCheckedItemStateMoreThanZero', {checkedItemStateMoreThanZero: isCheckedItemStateMoreThanZero});
                $store.commit('SetValueViewCostPrice', {checkedViewCostPrice: checkedViewCostPrice});
                $store.commit('SetCheckedSaleWithoutCurrency', {checkedSaleWithoutCurrency: isCheckedSaleWithoutCurrency});
                $store.commit('SetCheckedAccessToDebtSale', {checkedAccessToDebtSale: isCheckedAccessToDebtSale});
                $store.commit('SetOffChangeSalePrice', {offChangeSalePrice: offChangeSalePrice});
                $store.commit('setCompareSalePriceToPurchasePrice', {compareSalePriceToPurchasePrice: compareSalePriceToPurchasePrice});
            } catch (error) {
                console.log(error);
            }
        }
    },
    async created() {
        this.loading = true;
        await this.$store.dispatch("getPermissions").catch(err => console.log(err));
        await this.getCompanyParams();
        await this.loadWarehouses();
        await this.$store.dispatch("getDefaultClientFromApi").catch(err => console.log(err));
        await this.$store.dispatch("getDefaultClientFromApi").catch(err => console.log(err));
        await this.$store.dispatch("getDefaultEmployee").catch(err => console.log(err));
        await this.$store.dispatch("getTelegramDefaultClient").catch(err => console.log(err));
        await this.$store.dispatch("loadOrganization").catch(err => console.log(err));
        await this.$store.dispatch("getAllCurrencies", {
            skip: 0,
            limit: null,
            sort: null,
            desc: null
        }).catch(err => console.log(err));
        this.loading = false;
    }
}
</script>

<style scoped>

</style>
