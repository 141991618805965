import axios from 'axios';
import store from "../store";
import router from "../router";

const MountInterceptor = {
    mount401Interceptor() {
        return axios.interceptors.response.use(
            function (response) {
                return response
            }, async function (error) {
                console.log(error + 'mount401Interceptor');
                if (error.request.status === 401) {
                    store.dispatch('signOut').then(() => {
                        router.push("/user/login");
                    });
                    throw error
                } else if (error.request.status === 500) {
                    throw error
                }
            }
        )
    },
}
export default MountInterceptor
